import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

let timer;

function checkCredentials() {

  //  Check state
  if (sessionStorage.getItem('spaState') === 'ready') {

    if (process.env.NODE_ENV === 'development') {
      console.log('starting');
    }

    //  Stop checking
    clearInterval(timer);

    //  Start Single SPA
    const routes = constructRoutes(microfrontendLayout);
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return System.import(name);
      },
    });
    const layoutEngine = constructLayoutEngine({ routes, applications });

    applications.forEach(registerApplication);
    layoutEngine.activate();
    start();

    if (process.env.NODE_ENV === 'development') {
      console.log('started');
    }

    //  Set state
    sessionStorage.setItem('spaState', 'loaded');
  } else {

    if (process.env.NODE_ENV === 'development') {
      console.log('check failed');
    }
  }
}

//  Initial check
checkCredentials();

//  Continuous check
if (sessionStorage.getItem('spaState') !== 'ready') {
  timer = setInterval(checkCredentials, 500);
}

//  Stop check after 30 secounds
setTimeout(() => {

  clearInterval(timer);
  if (process.env.NODE_ENV === 'development') {
    console.log('check stopped');
  }
}, 5 * 1000);